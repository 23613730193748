import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import SlicesWrapper from 'components/slices/containers/homepage';
import RenderHomeWrapper from 'components/HOC/RenderHomeWrapper';
import filterPrismicDataByLanguage from 'utils/functions/filterPrismicDataByLanguage';

const HomePage = ({ data, preview, pageContext, location}) => {
  const language = preview ? data.prismicHomepage.lang : pageContext.lang;
  const alternateLanguages = preview ? data.prismicHomepage.alternate_languages : pageContext.alternateLanguages;
  
  const slices = data.prismicHomepage.data.body;
  const footerProps = filterPrismicDataByLanguage(data.allPrismicFooterV2.edges, language);
  const { node: headerData } = filterPrismicDataByLanguage(data.allPrismicHeadermenuV2.edges, language);
  
  const filteredUniversalPhoneNumber = filterPrismicDataByLanguage(data.allPrismicUniversalPhoneNumber.edges, language);
  const { node: {data: { phone_local }}} = filteredUniversalPhoneNumber;

  const defaultBannerImage = data.prismicUniversalBannerImage.data.banner_image;

  let headData = {
    meta_title: data.prismicHomepage.data.meta_title,
    meta_description: data.prismicHomepage.data.meta_description,
    meta_keywords: data.prismicHomepage.data.meta_keywords,
  }

  const languageCodes = footerProps.node.data.language.map(el => el.country_code);

  // add open graph meta data if available
  if(data?.prismicHomepage?.data?.body2?.length > 0) {
    const filteredOpenGraphData = data.prismicHomepage.data.body2.filter(el => el.slice_type === "open_graph");

    if (filteredOpenGraphData?.length > 0) {
      const openGraphData = filteredOpenGraphData[0]?.primary;

      if (openGraphData?.og_title) {
        headData = {
          ...headData,
          og_title: openGraphData.og_title,
          og_description: openGraphData?.og_description || openGraphData?.og_title,
          og_image: openGraphData?.og_image?.url.split('?')[0] || defaultBannerImage.url.split('?')[0],
          og_type: openGraphData?.og_type || 'website',
        }
      }
    }
  }

  return (
    <RenderHomeWrapper
      location={location}
      language={language}
      preview={preview}
      alternateLanguages={alternateLanguages}
      localePhoneNumber={data.allPrismicUniversalPhoneNumber.edges}
      phoneNumber={phone_local}
      universalProps={data.allPrismicUniversalProperties.edges}
      errorMessages={data.allPrismicPropertiesErrorMessages.edges}
      icons={data.allPrismicUniversalProperties.edges}
      footerProps={footerProps}
      headerData={headerData}
      meta={headData}
      languageCodes={languageCodes}
      component={ 
        <SlicesWrapper 
          slices={slices} 
          isHomePage={true}
          preview={preview}
          language={language}
        />
      }
    />
    
  )
};

export default HomePage;

HomePage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  pageContext: PropTypes.object,
  preview: PropTypes.bool
}

export const query = graphql`
  query HomeIndexQuery($id: String!, $lang: String!) {
    prismicHomepage(id: { eq: $id }, lang: { eq: $lang } ) {
      id
        type
        slugs
        lang
        prismicId
        data {
          meta_description
          meta_keywords
          meta_title
          body2 {
            ... on PrismicHomepageBody2OpenGraph {
              slice_type
              primary {
                og_title
                og_description
                og_type
                og_image {
                  dimensions {
                    height
                    width
                  }
                  url
                }
              }
            }
          }
          body {
            ... on PrismicHomepageBodyBannerImage {
              slice_type
              primary {
                display_heading1_as_paragraph
                heading1 {
                  raw {
                    text
                    type
                  }
                }
                heading2 {
                  raw {
                    text
                    type
                  }
                }
                heading3 {
                  raw {
                    type
                    text
                  }
                }
                text_position
                banner_image_size
                image {
                  url
                  mobile {
                    url
                    dimensions {
                      height
                      width
                    }
                  }
                  dimensions {
                    height
                    width
                  }
                }
              }
            }
            ... on PrismicHomepageBodyRichtext {
              id
              slice_type
              primary {
                text {
                  raw {
                    text
                    type
                  }
                }
              }
            }
          __typename ... on PrismicHomepageBodyFxairCardSection {
          id  
          slice_type
            items {
              card_text {
                html
                raw {
                  direction
                  spans {
                    data {
                      label
                      link_type
                      target
                      url
                    }
                    end
                    start
                    type
                  }
                  text
                  type
                }
                text
              }
              card_image {
                dimensions {
                  width
                  height
                }
                url
              }
              fxair_logo_url {
                url
              }
              image_position
              zoom_option
            }
          }
            ... on PrismicHomepageBodyCardSection {
              id
              slice_type
              items {
                card_button
                card_link {
                  url
                }
                card_image {
                  url
                  dimensions {
                    width
                    height
                  }
                }
                card_text {
                  raw {
                    text
                    type
                  }
                  html
                }
                card_title {
                  raw {
                    text
                    type
                  }
                  html
                }
              }
            }
          }
        }
    }
    allPrismicHeadermenuV2 {
      edges {
        node {
          ...HeaderData
        }
      }
    }
    prismicUniversalBannerImage {
      data {
        banner_image {
          url
        }
      }
    }
    allPrismicUniversalPhoneNumber {
      edges {
        node {
          ...PrismicUniversalPhoneNumber
        }
      }
    }
    allPrismicFooterV2 {
      edges {
        node {
          ...FooterData
        }
      }
    }
    allPrismicUniversalProperties {
      edges {
        node {
          ...PrismicUniversalProperties
        } 
      }
    }
    allPrismicPropertiesErrorMessages {
      edges {
        node {
          ...PrismicPropertiesErrorMessages
        }
      }
    }
    allPrismicFlightsearchproperties {
      edges {
        node {
         ...PrismicFlightsearchproperties
        }
      }
    }
  }
`