import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import RichPost from 'components/richText'
import RenderPostWrapper from 'components/HOC/RenderPostWrapper';
import UniversalFullWidthWrapper from 'components/HOC/UniversalFullWidthWrapper';

import filterPrismicDataByLanguage from 'utils/functions/filterPrismicDataByLanguage';

const Inspirational = ({ data, preview, location, pageContext }) => {

  const content = data.prismicInspirational
  const alternateLanguages = preview ? content.alternate_languages : pageContext.alternateLanguages
  const language = preview ? content.lang : pageContext.lang;
  const slices = content?.data?.body1;
  const richContent = preview ? content.data.post : content.data.post.raw;
  
  const templateLayout = content.data.layout || 'two-column'; 
  const heroImage = content.data.hero_image;
  const filteredUniversalPhoneNumber = filterPrismicDataByLanguage(data.allPrismicUniversalPhoneNumber.edges, language);
  const { node: {data: { phone_local }}} = filteredUniversalPhoneNumber;
  const { node: headerData } = filterPrismicDataByLanguage(data.allPrismicHeadermenuV2.edges, language);
  const defaultBannerImage = data.prismicUniversalBannerImage.data.banner_image;
  const errorMessages = filterPrismicDataByLanguage(data.allPrismicPropertiesErrorMessages.edges, language);
  const footerProps = filterPrismicDataByLanguage(data.allPrismicFooterV2.edges, language);
  const universalProps = filterPrismicDataByLanguage(data.allPrismicUniversalProperties.edges, language);

  //language codes from header query for SEO component
  const languageCodes = footerProps.node.data.language.map(el => el.country_code);

  // press release properties for sidebar widget
  const inspirationalProperties = !preview && filterPrismicDataByLanguage(data.allPrismicPropertiesInspirational.edges, language);

  const hasWidget = preview ? data?.allPrismicWidgetData?.edges?.length > 0 
  : inspirationalProperties?.node?.data?.body?.length > 0;

  const widgetData = preview ? data?.allPrismicWidgetData?.edges : inspirationalProperties?.node?.data?.body;
  const bannerImageSlice = slices && slices.find(el => {
    if (el.slice_type === "banner_image") return el
  });

  const tableOfContentsSlice = slices && slices.filter(el => el.slice_type === "table_of_contents" );

  let headData = {
    meta_title: content.data.meta_title,
    meta_description: content.data.meta_description,
    meta_keywords: content.data.meta_keywords,
  }

  // add open graph meta data if available
  if(content?.data?.body2?.length > 0) {
    const filteredOpenGraphData = content.data.body2.filter(el => el.slice_type === "open_graph");

    if (filteredOpenGraphData?.length > 0) {
      const openGraphData = filteredOpenGraphData[0]?.primary;

      if (openGraphData?.og_title) {
        headData = {
          ...headData,
          og_title: openGraphData.og_title,
          og_description: openGraphData?.og_description || openGraphData?.og_title,
          og_image: openGraphData?.og_image?.url.split('?')[0] || defaultBannerImage.url.split('?')[0],
          og_type: openGraphData?.og_type || 'website',
        }
      }
    }
  }

  const bodyComponent = ( 
    <div style={templateLayout === "full-width" && {margin: '20px 0px'}}>
      <RichPost 
        richContent={richContent}
        slices={content?.data?.body1}
        preview={preview}
        language={language}
      />
    </div>
  )

  const wrapperProps = { 
    heroImage: heroImage, 
    meta: headData, 
    preview: preview, 
    location:location, 
    content: content, 
    language: language,
    alternateLanguages: alternateLanguages,
    languageCodes: languageCodes,
    localePhoneNumber: data.allPrismicUniversalPhoneNumber.edges,
    phoneNumber: phone_local,
    defaultBannerImage: defaultBannerImage,
    errorMessages: data.allPrismicPropertiesErrorMessages.edges,
    icons: universalProps,
    footerProps: footerProps,
    universalProps: universalProps,
    headerData: headerData,
    widget: hasWidget ? widgetData : [],
    bannerImageSlice: bannerImageSlice,
    showFlightSearchWidget: false,
    flightSearchErrorMessages: errorMessages,
    sideBarProps: universalProps,
    tableOfContentsSlice: tableOfContentsSlice,
    component: bodyComponent,
  }

  if (templateLayout === "full-width" ) {
    return <UniversalFullWidthWrapper {...wrapperProps} />

  } else {
    return <RenderPostWrapper {...wrapperProps} /> 

  }
 
};

export default Inspirational;

export const query = graphql`
  query inspirationalQuery($id: String!) {
    prismicInspirational(id: { eq: $id }) {
      ...Inspirational
    }
    allPrismicPropertiesInspirational {
      edges {
        node {
          id
          lang
          data {
            show_flight_search_widget
            body {
              primary {
                widget_document {
                  document {
                    data {
                      widget_type
                      widget_title {
                        text
                      }
                      widget_text {
                        raw {
                          text
                          type
                        }
                      }
                      widget_button_text
                      widget_button_link {
                        url
                        target
                      }
                      phone_number
                      card_image {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }  
    }
    allPrismicPropertiesAirfield {
      edges {
        node {
          ...PrismicPropertiesAirfield
        }
      }
    }
    allPrismicHeadermenuV2 {
      edges {
        node {
          ...HeaderData
        }
      }
    }
    prismicUniversalBannerImage {
      data {
        banner_image {
          url
        }
      }
    }
    allPrismicUniversalPhoneNumber {
      edges {
        node {
          ...PrismicUniversalPhoneNumber
        }
      }
    }
    allPrismicFooterV2 {
      edges {
        node {
          ...FooterData
        }
      }
    }
    allPrismicFlightsearchproperties {
      edges {
        node {
         ...PrismicFlightsearchproperties
        }
      }
    }
    allPrismicUniversalProperties {
      edges {
        node {
          ...PrismicUniversalProperties
        } 
      }
    }
    allPrismicPropertiesErrorMessages {
      edges {
        node {
          ...PrismicPropertiesErrorMessages
        }
      }
    }
  }
`;

Inspirational.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  pageContext: PropTypes.object,
  preview: PropTypes.bool
};
