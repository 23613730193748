import React from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';

import Banner from 'components/banner';
import RenderRichText from 'utils/richText/renderRichText';
import Card from 'components/card';
import ArrowButton from 'components/buttons/ArrowButton';

import { FullWidthContainer, FullWidthCardContainer, FullWidthSmallCardsContainer } from 'components/grid/styled';

import { StyledInspirationalSlicesWrapper, StyledDestinationTextWrapper } from 'components/slices/styled';

const SlicesWrapper = ({ slices, preview, language, uid, flightSearchComponent, allInspirational, cardsToShow, handleCardsPagination }) => {  
  return (
    <StyledInspirationalSlicesWrapper>
      {slices ? 
        slices.map((slice, index) => {
          const sliceType = slice.slice_type;

          return (
            <React.Fragment key={slice.id}>
              {(() => {
                switch (sliceType) {
                  case "banner_image": 

                    const bannerImageSrc = slice.primary.image.url;
                    const displayHeadingOneAsParagraph = slice.primary.display_heading1_as_paragraph
                    const textPosition = slice.primary.text_position;
                    const headingOneRichText = slice.primary.heading1.length > 0 && preview ? slice.primary.heading1 : slice.primary.heading1.raw;
                    
                    const headingTwoRichText = slice.primary.heading2 ? slice.primary.heading2.length > 0 && preview ? slice.primary.heading2 : slice.primary.heading2.raw : [];
                    
                    const headingThreeRichText = slice.primary.heading3 ? slice.primary.heading3.length > 0 && preview ? slice.primary.heading3 : slice.primary.heading3.raw: [];
                    
                    const paragraphRichText = slice.primary.banner_text ? slice.primary.banner_text.length > 0 && preview ? slice.primary.banner_text[0].raw : slice.primary.banner_text.raw : [];

                    const isBottomBanner = slice.primary.display_banner_at_the_bottom;
                    const bannerImageSize = slice.primary.banner_image_size
                  
                    return (
                      <Banner 
                        desktopLarge={bannerImageSize === "large" ? true: false}
                        desktopMedium={bannerImageSize === "medium" ? true: false}
                        desktopSmall={bannerImageSize === "small" ? true: false}
                        isHomePage={false}
                        headingOne={headingOneRichText && <RenderRichText richTextArray={headingOneRichText} />}
                        headingTwo={headingTwoRichText && <RenderRichText richTextArray={headingTwoRichText} />}
                        headingThree={headingThreeRichText && headingThreeRichText && <RenderRichText richTextArray={headingThreeRichText} />}
                        displayHeadingOneAsParagraph={displayHeadingOneAsParagraph}
                        textPosition={textPosition} 
                        bannerImageSrc={bannerImageSrc}
                        text={paragraphRichText && <RenderRichText richTextArray={paragraphRichText} />}
                        isBottomBanner={isBottomBanner}
                        buttonComponent={null}
                      />
                  )

                  case "inspirational_main_featured_article": 
                    const cardImageUrl = slice.primary.card_image.url;
                    const cardText = slice.primary.card_text && preview ? slice.primary.card_text : slice.primary.card_text.raw;
                    const imagePosition = slice.primary.image_position;
                    const cardLink = preview ? '/' : `${slice.primary.card_link.data.category}/${slice.primary.card_link.data.url}`;

                    return (
                      <FullWidthCardContainer>
                        <Card 
                          buttonComponent={
                            <ArrowButton 
                              buttonText={process.env.LANG_CODE=='fr-fr' || language === 'fr-fr' ? "VOIR PLUS" : "READ MORE"}
                              buttonHref={cardLink}
                              theme="blue"
                              isLink={true}
                              language={language}
                            /> 
                          }
                          cardText={<RenderRichText richTextArray={cardText} />}
                          headingText={null}
                          cardImageUrl={cardImageUrl}
                          hasSeperator={false}
                          desktopOneRowCard={true}
                          alignImage={imagePosition}
                          buttonAlign="flex-start"
                          language={language}
                        />
                        
                      </FullWidthCardContainer>
                  )

                  case "inspirational_featured_list":
                    let inspirationalList;
 
                    if (preview) {
                      inspirationalList = allInspirational.filter(el=> el.cardImageUrl !== undefined);
                    } else {
                      inspirationalList = allInspirational
                        .filter(el => el.cardImageUrl !== null && el.cardDescription.trim() !== '');
                    }

                    // filter out test document
                    const filteredInspirationalList = inspirationalList.filter(el=> el.cardTitle !== "title");

                    const check = filteredInspirationalList.length % 4 == 0;
                    const b = Math.ceil(filteredInspirationalList.length / 4) * 4;

                    if (!preview && check === false) {
                      const c = b - filteredInspirationalList.length;

                      const x = [...Array(c).keys()]

                      x.map(el => {
                        filteredInspirationalList.push({
                          id: "empty"
                        })
                      })
                    }
                                        
                    return (
                      <FullWidthSmallCardsContainer  
                        marginTop="140px" 
                        marginBottom="40px"
                        marginTopMobile="40px"
                      >
                        {
                          filteredInspirationalList.slice(0, cardsToShow).map(el => {
                            const cardImageUrl = el.cardImageUrl;
                            const cardDescription = el.cardDescription
                            const cardTitle = el.cardTitle;
                            const cardLink = el.cardLink;

                            if (el.id !== "empty") {
                              return (
                                <Card 
                                  headingText={
                                    <h4 style={{ textAlign: "left", marginBottom: "10px", marginTop: "10px", fontSize: "18px" }}>
                                      {cardTitle}
                                    </h4>
                                  }
                                  cardText={<p>{cardDescription}</p>}
                                  sizeSmall={true}
                                  cardImageUrl={cardImageUrl}
                                  hasSeperator={false}
                                  desktopOneRowCard={false}
                                  isCardLink={true}
                                  cardLink={cardLink}
                                  textAlign="left"
                                  language={language}
                                />
                              )
                            } else {
                              return (
                                <Card 
                                  sizeSmall={true}
                                  hasSeperator={false}
                                  desktopOneRowCard={false}
                                  isCardLink={true}
                                  className="aircraft-card"
                                  hideImageInMobile={false}
                                  isFakeCard={true}
                                />
                              )
                              
                            }

                          })
                        }
                        {
                          cardsToShow < filteredInspirationalList.length ?
                            (
                              <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                                <ArrowButton 
                                  isLink={false}
                                  theme="red"
                                  buttonText={process.env.LANG_CODE=='fr-fr' ? 'voir plus' : 'load more'}
                                  onClick={handleCardsPagination}
                                  padding="25px"
                                />
                              </div>
                            )
                          : null
                        }
                        
                      </FullWidthSmallCardsContainer>
                  )

                  case 'richtext': 
                    const richTextArray = preview ? slice.primary.text : slice.primary.text.raw;

                    return (
                      <FullWidthContainer>
                        <StyledDestinationTextWrapper>
                          <RenderRichText 
                            richTextArray={richTextArray}
                          />
                        </StyledDestinationTextWrapper>
                      </FullWidthContainer>
                  )
                  
                  default: 
                    return <React.Fragment />
                }
              })()}
            </React.Fragment>
          )
        })
      : null}
    </StyledInspirationalSlicesWrapper>
  )
};

SlicesWrapper.propTypes = {
  allInspirational: PropTypes.array,
  cardsToShow: PropTypes.number,
  flightSearchComponent: PropTypes.object,
  handleCardsPagination: PropTypes.func,
  language: PropTypes.string,
  preview: PropTypes.bool,
  slices: PropTypes.array,
  uid: PropTypes.string,
};

export default SlicesWrapper;
